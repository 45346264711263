/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const title = data.site?.siteMetadata?.title;

  return (
    <header className="global-header">
      <a href="/" aria-label={title}>
        <img src="../assets/logo.png" alt="" width="200" height="54" />
      </a>
      <img className="header-glpyh" src="../assets/open-glyph.svg" alt="" />
    </header>
  )
}

export default Header
