/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

const DiamondDivider = (props) => {
  const classNames = ['divider'];
  if (props.className) {
    classNames.push(props.className);
  }
  return (
    <div className={classNames.join(' ')}>
        <img className="divider--diamond" src="../assets/diamond.svg" alt="" />
    </div>
  )
}

export default DiamondDivider
