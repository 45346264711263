/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

const EntryButton = ({ post }) => {
  function goToEntry() {
    // The page transition animation is 300ms long.
    document.querySelector('.global-wrapper').classList.add('disappear');
    setTimeout(() => {
      window.location = post.fields.slug;
    }, 250);
  }
  function keyUpHandler(event) {
    if (event.which === 13) {
      goToEntry();
    }
  }
  return (
    <div className="entry-button"
          tabindex="0"
          onKeyUp={(event) => keyUpHandler(event)}
          onClick={() => goToEntry()}>
      <div className="entry-button--hover">
        <div className="header">
          <h3>
            <a tabindex="-1" href={post.fields.slug} onClick={(e) => { e.preventDefault(); }}>{post.frontmatter.title}</a>
          </h3>
          <p>{post.frontmatter.date}</p>
        </div>
        <p>{post.frontmatter.description}</p>
      </div>
    </div>
  )
}

export default EntryButton
