import Header from "../components/header"
import React, { useEffect, useRef } from 'react';

const DISAPPEAR_CSS_CLASS = 'disappear';
const APPEAR_CSS_CLASS = 'appear';
const INVISIBLE_CSS_CLASS = 'invisible';

const Layout = ({ children }) => {
  const year = new Date().getFullYear();
  const container = useRef(null);

  useEffect(() => {
    container.current = document.querySelector('.global-wrapper');
    window.scrollTo(0, 0);

    container.current.addEventListener('webkitAnimationEnd', () => {
      resetPage();
    });
    container.current.addEventListener('animationend', () => {
      resetPage();
    });

    // When component updates, the disappear class might have been removed.
    // If the component will be visible (indicated by the lack of `DISAPPEAR_CSS_CLASS`)
    // add DISAPPEAR_CSS_CLASS to the global wrapper.
    if (!container.current.classList.contains(INVISIBLE_CSS_CLASS)) {
      container.current.classList.add(INVISIBLE_CSS_CLASS, APPEAR_CSS_CLASS);
    } else {
      container.current.classList.add(APPEAR_CSS_CLASS);
    }

    if (!('ontouchstart' in window)) {
      container.current.classList.add('no-touch');
    }
  });

  function resetPage() {
    container.current.classList.remove(APPEAR_CSS_CLASS, INVISIBLE_CSS_CLASS);
  }

  return (
    <div className="global-wrapper invisible">
      <Header />
      <main>{children}</main>
      <img className="header-glpyh header-glpyh--close" src="../assets/open-glyph.svg" alt="" />
      <footer className="footer">
        Copyright @ {year} Aaron Chu.
      </footer>
    </div>
  )
}

export default Layout
